/* #loginSignup-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 50vh;
    background-color: gray;
    position: relative;
    margin: auto auto;
}

#root {
    text-align: center;
    height: 100vh;
    position: relative;
} */

#loginSignup-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Set the height to 100% of the viewport height */
}

#loginSignup-container {
    width: 50vh; /* Set the width to 50% of the viewport height */
    height: 50vh; /* Set the height to 50% of the viewport height */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: gray; */
    background-color: rgb(52, 53, 65);
    position: relative;
    margin: auto auto;
    border-radius: 15px;
}
