/* SchoolPlannerDashboard.css */
.school-planner-dashboard {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding: 20px;
}

.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.calendar-wrapper {
  width: 80%; /* Adjust the width as needed */
}

.calendar-navigation {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Create a 4-column grid for the navigation arrows */
  width: 100%; /* Ensure the navigation takes full width */
  margin-bottom: 10px;
}

.react-calendar_navigation_arrow {
  text-align: center;
  cursor: pointer;
}

/* Add this new class to center the month label */
.react-calendar_navigation_label {
  text-align: center;
  grid-column: 2 / span 2; /* Span two columns and center the label */
}

.tasks-container,
.events-container {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 5px;
}

/* Add additional styling as needed */

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}