.profileIcon {
    width: 32px;
    height: 32px;
    border-radius: 10px;
}

.pfpIconLoaded>div>a::after {
    margin-bottom: -3px;
}

/* hides the arrow next to dropdown */
.dropdown-toggle::after { 
    display: none !important;
}

#basic-nav-dropdown {
    padding: 0px;
}