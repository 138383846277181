.centerText {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
}

.wh-50vh {
    width: 50vh;
    height: 50vh;
}

.dflex {
    display: flex;
}

h1, h2 {
    align-items: center;
    display: flex;
}

.justifyCenter {
    justify-content: center;
}

#errorBx {
    /* justify-content: center; */
    flex-direction: column;
    justify-content: start;
}